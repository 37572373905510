<template>
<!-- 首页 -->
  <div>
    <!-- 头部 -->
    <div class="logoTop">
      <span><img src="../../assets/anluoyun.png" alt="" style="width:13%" /></span>
      <span @click="viewList()"><van-icon name="bars" /></span>
    </div>
    <van-popup v-model="tabShow" class="tabShow" position="right" :style="{ height: '100%' }" >
      <div style="margin:15px"> 
        <img src="../../assets/anluoyun.png" alt="" style="width:80%;margin:0 10%">
      </div>
      <ul>
        <li class="select"><router-link to="/anLuoYun">网站首页</router-link></li>
        <li><router-link to="/anLuoYun/about">关于我们</router-link></li>
        <li><router-link to="/anLuoYun/serve">企业项目</router-link></li>
        <li><router-link to="/anLuoYun/recruit">企业招聘</router-link></li>
        <li><router-link to="/anLuoYun/message">企业资讯</router-link></li>
        <li><router-link to="/anLuoYun/contact">联系我们</router-link></li>
      </ul>
    </van-popup>
    <!-- 轮播图 -->
    <van-swipe class="my-swipe swipes" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="../../assets/swiper_bg1.png" alt="">
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../assets/swiper_bg2.png" alt="">
      </van-swipe-item>
    </van-swipe>
    <van-grid class="wktype" :border="false">
      <van-grid-item icon="photo-o" @click="gotoUrl('/anLuoYun/about')">
        <van-image
          class="iconimg"
          :src="require('../../assets/wangshangbanshi.png')"
        ></van-image>
        <span class="wktext">企业简介</span>
      </van-grid-item>
      <van-grid-item icon="photo-o" @click="gotoUrl('/anLuoYun/serve')">
        <van-image
          class="iconimg"
          :src="require('../../assets/shequhuodong.png')"
        ></van-image>
        <span class="wktext">企业项目</span>
      </van-grid-item>
      <van-grid-item icon="photo-o" @click="gotoUrl('/anLuoYun/recruit')">
        <van-image
          class="iconimg"
          :src="require('../../assets/zixunzhongxin.png')"
        ></van-image>
        <span class="wktext">企业招聘</span>
      </van-grid-item>
      <template>
        <van-grid-item icon="photo-o" @click="gotoUrl('/anLuoYun/message')">
          <van-image
            class="iconimg"
            :src="require('../../assets/shequfengcai.png')"
          ></van-image>
          <span class="wktext">企业资讯</span>
        </van-grid-item>
      </template>
    </van-grid>
    <!-- 内容 -->
    <article class="center">
      <div class="center_title">智慧社区/智慧街道</div>
      <div class="center_subtitle">集党建、网格、综治、民生于一体的街道大数据平台</div>
      <div class="center_box">
        <div class="center_boxa">
          <img src="../../assets/home1.png" alt="" style="width:70%">
        </div>
        <div class="center_boxb">
          <div>智慧党建</div>
          <div>平台利用大数据以及强大的业主信息资料系统，构建起一个全新的线上阵地，为党员和党组织建立沟通的桥梁</div>
        </div>
      </div>
      <div class="center_box">
        <div class="center_boxa">
          <img src="../../assets/home2.png" alt="" style="width:70%">
        </div>
        <div class="center_boxb">
          <div>综治管理</div>
          <div>全面监管辖区重点人口，对管辖区内重点青少年、特殊人群单独标记，重点跟踪。构建随时监控跟踪的高效平台，及时调解矛盾纠纷，化解不稳定因素</div>
        </div>
      </div>
      <div class="center_box">
        <div class="center_boxa">
          <img src="../../assets/home3.png" alt="" style="width:65%">
        </div>
        <div class="center_boxb">
          <div>网格化管理</div>
          <div>将街道划分为一个个网格，做到每个网格有一个负责人，真正做到责任到人、关系到户</div>
        </div>
      </div>
      <div class="center_box">
        <div class="center_boxa">
          <img src="../../assets/home6.png" alt="" style="width:70%">
        </div>
        <div class="center_boxb">
          <div>群众留言建议</div>
          <div>群众发现问题，通过手机实时拍照上报，描述事件详情，街道管理员接收到上报事件，实时进行回复，有效拉近双方距离，提高街道工作开展针对有效性</div>
        </div>
      </div>
      <div class="center_box">
        <div class="center_boxa">
          <img src="../../assets/home5.png" alt="" style="width:70%">
        </div>
        <div class="center_boxb">
          <div>智慧养老</div>
          <div>整合了社区养老、居家养老、机构养老、医养结合等多种养老场景，覆盖全场景养老管理体系与信息管理系统，为不同养老单位提供丰富的、多维度的智能养老方案。</div>
        </div>
      </div>
            <div class="center_box">
        <div class="center_boxa">
          <img src="../../assets/home4.png" alt="" style="width:70%">
        </div>
        <div class="center_boxb">
          <div>街道志愿者活动管理</div>
          <div>给街道提供线上志愿者招募的平台，有效提高群众响应性，也方便街道统计查询报名者信息，提高管理效率</div>
        </div>
      </div>
    </article>
    <!-- 底部 -->
    <aside class="buttonVal">
      <div>电 话：18837992186</div>
      <div>地 址：洛阳市经济技术开发区长夏门街32号863创智广场1 幢2201</div>
      <div>版 权：河南安洛云信息科技有限公司</div>
      <div>备案号：豫ICP备17050318号-1</div>
    </aside>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabShow:false,
    }
  },
  methods:{
    // 顶部tab菜单显示
    viewList(){
      if(this.tabShow == true){
        this.tabShow = false
      }else{
        this.tabShow = true
      }
    },
    pushTo(row){

    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  }
}
</script>
<style scoped>
.logoTop {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 65px;
  background: #fff;
  box-shadow: 0px 1px 5px #ccc;
}
.logoTop span:nth-child(1){
  position: absolute;
  left: 15px;
  top: 10px;
}
.logoTop span:nth-child(2){
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 37px;
}
.tabShow {
  width: 130px;
}
.tabShow li {
  padding: 8px 0px;
  text-align: center;
  color: #000;
}
.tabShow a {
  color: #000;
}
.tabShow .select{
  background: #ccc;
} 
.tabShow .select a{
  color: #0076f6;
} 
.swipes img{
  width: 100%;
  height: 30%;
}
.swipes {
  top: 65px;
}
.wktype {
  padding: 60px 10px 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 13px;
}
.center {
  margin-top: 15px;
  padding: 8px 15px;
  /* padding-bottom: 150px; */
}
.center .center_title{
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.center .center_subtitle {
  margin: 8px 0;
  font-size: 14px;
  color: #ccc;
  text-align: center;
}
.center .center_box {
  background: #f5f8fd;
  border-radius: 10px;
  padding: 15px;
  margin: 8px 0;
}
.center .center_boxa {
  display: inline-block;
  width: 40%;
}
.center .center_boxb {
  display: inline-block;
  width: 60%;
}
.center_boxb div:nth-child(1){
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}
.center_boxb div:nth-child(2){
  font-size: 14px;
}
.buttonVal {
  /* position: fixed;
  bottom: 0px; */
  padding: 8px 15px;
  background: #333333;
  font-size: 14px;
  color: #fff;
}
.buttonVal div {
  margin: 5px;
}
</style>